import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import CheckConfigView from './CheckConfigView';
import CheckoutView from './CheckoutView';
import ControlConfigView from './ControlConfigView';
import CustomerPropertiesLookupView from './CustomerPropertiesLookupView';
import CustomerValidationView from './CustomerValidationView';
import DiscountReasonsView from './DiscoutReasonsView';
import FlowView from './FlowView';
import FulfillmentConfigView from './FulfillmentConfigView';
import PaymentConfigurationView from './PaymentConfigurationView';
import TaxCategoriesView from './TaxCategoriesView';
import TseConfigurationView from './TseConfigurationView';
import VposView from './VposView';
import VoucherProvidersView from './VoucherProvidersView';
import DepositReturnVoucherProvidersView from './DepositReturnVoucherProvidersView';
import ServiceCredentialsView from './ServiceCredentialsView';
import CouponConfigurationView from './CouponConfigurationView';
import ReturnReasonsView from './ReturnReasonsView';

export default function CheckoutRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="general" replace />} />
      <Route path="/general" element={<CheckoutView />} />
      <Route path="/paymentConfiguration" element={<PaymentConfigurationView />} />
      <Route path="/flows" element={<FlowView />} />
      <Route path="/vpos" element={<VposView />} />
      <Route path="/controlConfig" element={<ControlConfigView />} />
      <Route path="/tseConfiguration" element={<TseConfigurationView />} />
      <Route path="/discountReasons" element={<DiscountReasonsView />} />
      <Route path="/taxCategories" element={<TaxCategoriesView />} />
      <Route path="/checkConfig" element={<CheckConfigView />} />
      <Route path="/customerPropertiesLookup" element={<CustomerPropertiesLookupView />} />
      <Route path="/fulfillmentConfig" element={<FulfillmentConfigView />} />
      <Route path="/customerValidation" element={<CustomerValidationView />} />
      <Route path="/voucherProviders" element={<VoucherProvidersView />} />
      <Route path="/depositReturnVoucherProviders" element={<DepositReturnVoucherProvidersView />} />
      <Route path="/serviceCredentials" element={<ServiceCredentialsView />} />
      <Route path="/couponConfiguration" element={<CouponConfigurationView />} />
      <Route path="/return" element={<ReturnReasonsView />} />
    </Routes>
  );
}
